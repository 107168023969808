var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"invite-user-modal","ok-only":"","size":"lg","no-close-on-backdrop":"","content-class":"shadow","title":_vm.title,"ok-title":"Accept"},on:{"shown":_vm.getData,"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isProcessing},on:{"click":_vm.inviteUser}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isProcessing),expression:"isProcessing"}],attrs:{"small":""}}),_c('span',[_vm._v(" Send ")])],1)],1)],1)]}}])},[_c('validation-observer',{ref:"simpleRules",attrs:{"id":"tour-invite-user-form"}},[_c('custom-switch',{attrs:{"left-text":"Existing User","right-text":"New User","initial-value":false,"show-icons":false},model:{value:(_vm.isNewUser),callback:function ($$v) {_vm.isNewUser=$$v},expression:"isNewUser"}}),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.companyInvite && !_vm.isNewUser)?_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.emailOptions,"reduce":function (e) { return e.value; },"create-option":function (email) { return ({ label: email, value: email }); },"placeholder":"Invite user by email","taggable":""},on:{"search":_vm.fetchUsers},scopedSlots:_vm._u([{key:"option",fn:function(data){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(data.name))]),_c('br'),_c('span',[_vm._v(" "+_vm._s(data.label))])]}}],null,true),model:{value:(_vm.inviteForm.email),callback:function ($$v) {_vm.$set(_vm.inviteForm, "email", $$v)},expression:"inviteForm.email"}}):_c('b-form-input',{attrs:{"placeholder":"Invite user by email"},model:{value:(_vm.inviteForm.email),callback:function ($$v) {_vm.$set(_vm.inviteForm, "email", $$v)},expression:"inviteForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Company"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Select a Company","options":_vm.companies,"state":errors.length > 0 ? false : null,"reduce":function (c) { return c.value; },"disabled":true,"clearable":false},model:{value:(_vm.inviteForm.companyId),callback:function ($$v) {_vm.$set(_vm.inviteForm, "companyId", $$v)},expression:"inviteForm.companyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Role in company"}},[_c('validation-provider',{attrs:{"name":"Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Select a Role","options":_vm.roles,"state":errors.length > 0 ? false : null,"reduce":function (r) { return r.value; },"disabled":_vm.emailOptions.some(function (user) { return user.value === _vm.inviteForm.email; })},model:{value:(_vm.inviteForm.roleId),callback:function ($$v) {_vm.$set(_vm.inviteForm, "roleId", $$v)},expression:"inviteForm.roleId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(!_vm.companyInvite)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Project"}},[_c('validation-provider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Select a Project","options":_vm.projects,"state":errors.length > 0 ? false : null,"reduce":function (p) { return p.value; },"disabled":_vm.companyInvite ? false : true,"clearable":_vm.companyInvite ? true : false},model:{value:(_vm.inviteForm.projectId),callback:function ($$v) {_vm.$set(_vm.inviteForm, "projectId", $$v)},expression:"inviteForm.projectId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2741975268)})],1)],1),_c('b-col',[(_vm.inviteForm.projectId)?_c('b-form-group',{attrs:{"label":"Role in project"}},[_c('validation-provider',{attrs:{"name":"Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Select a Role","options":_vm.projectRoles,"state":errors.length > 0 ? false : null,"reduce":function (r) { return r.value; }},model:{value:(_vm.inviteForm.projectRole),callback:function ($$v) {_vm.$set(_vm.inviteForm, "projectRole", $$v)},expression:"inviteForm.projectRole"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2377813195)})],1):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }