<template>
  <div>
    <b-row class="justify-content-between">
      <b-col>
        <b-button
          variant="flat-secondary"
          @click="$router.go(-1)"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="15"
          />
          Back
        </b-button>
      </b-col>

      <b-col>
        <b-button
          v-if="companyRole=='owner'"
          id="tour-step-0"
          v-b-modal.create-project-modal
          v-b-tooltip="'Create project for company'"
          variant="primary"
          class="float-right"
        >
          <feather-icon
            icon="PlusCircleIcon"
          />
          Create Project
        </b-button>
      </b-col>
    </b-row>
    <div
      v-if="companyInfo"
      class="ml-1 mt-2 "
    >
      <h3 class="mb-3">
        {{ companyInfo.name }} / Projects
      </h3>
    </div>
    <b-overlay :show="processing">
      <b-row
        v-if="projects.length"
        class="mt-5"
      >
        <b-col
          v-for="value of projects"
          :key="value.uuid"
          cols="4"
          class="mb-3"
        >
          <project-card
            :value="value"
            :is-admin="userType === 'admin'"
            :has-invite-permission="$route.params.hasInvitePermission"
            @editProject="(value) => showEditProjectModal(value)"
            @inviteUser="(value) => projectSelectedForInvite = value"
          />
        </b-col>
      </b-row>
      <b-card
        v-else
        class="text-center"
      >
        <h5>No Projects Found</h5>
      </b-card>
    </b-overlay>
    <create-edit-project
      :company-id="companyId"
      :project="selectedProject"
      @onSave="getProjects(1)"
      @close="selectedProject = null"
    />
    <invite-user
      v-if="$route.params.hasInvitePermission"
      :selected-company="companyInfo"
      :selected-project="projectSelectedForInvite"
      @close="projectSelectedForInvite=null"
    />
    <pagination
      v-if="projects.length"
      :total-items="totalItems"
      :per-page="perPage"
      @onPaginationChange="getProjects"
    />

  </div>

</template>
<script>
import {
  BButton, BOverlay, BCard,
} from 'bootstrap-vue'
import CreateEditProject from '@/views/Settings/company/projects/createEdit.vue'
// import ConfirmDelete from '@/views/common/components/ConfirmModal.vue'
// import AddRemoveUser from '@/views/Settings/company/projects/AddRemoveUser.vue'
import useApollo from '@/plugins/graphql/useApollo'
import Pagination from '@/views/common/components/Pagination.vue'
import { mapGetters } from 'vuex'
import ProjectCard from './projectCard.vue'
import InviteUser from '../../Users/InviteUser.vue'

export default {
  components: {
    BButton,
    BOverlay,
    Pagination,
    InviteUser,
    ProjectCard,
    CreateEditProject,
    BCard,
  },
  data() {
    return {
      processing: false,
      projects: [],
      perPage: 10,
      totalItems: 0,
      selectedProject: null,
      companyInfo: null,
      projectSelectedForInvite: null,
    }
  },
  computed: {
    ...mapGetters('project', {
      userType: 'USER_MENU',
      companyRole: 'GET_COMPANY_ROLE',
    }),
    companyId() {
      return this.$route.params.uid
    },
    companyRole() {
      return this.$store.state.project.companyRole
    },
  },
  mounted() {
    this.getProjects()
    this.getCompany()
    // this.companyInfo = {
    //   uuid: this.companyId,
    // }
  },
  methods: {
    getProjects(page = 1) {
      this.processing = true
      useApollo.project.getCompanyProject({
        companyId: this.companyId,
        first: this.perPage,
        page,
      }).then(response => {
        this.projects = response.data.company?.projects?.data
        this.totalItems = response.data.company?.projects?.total
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.processing = false })
    },
    getCompany() {
      useApollo.company.getCompany(this.companyId).then(response => {
        this.companyInfo = response.data?.company
      })
    },
    showEditProjectModal(value) {
      this.selectedProject = value
      this.$nextTick(() => {
        this.$bvModal.show('create-project-modal')
      })
    },
  },

  // components: {
  //   BTable,
  //   BButton,
  //   CreateEditProject,
  //   ConfirmDelete,
  //   AddRemoveUser,
  //   Pagination,
  //   BOverlay,
  // },
  // directives: {
  //   'b-modal': VBModal,
  // },
  // props: {
  //   companyId: {
  //     type: [Number, String],
  //     required: true,
  //   },
  //   title: {
  //     type: [Number, String],
  //     required: true,
  //   },
  // },
  // data() {
  //   return {
  //     rows: [],
  //     fields: [
  //       { key: 'name', label: 'Name', thClass: 'bg-primary text-white' },
  //       { key: 'description', label: 'Description', thClass: 'bg-primary text-white' },
  //       { key: 'action', label: 'Action', thClass: 'bg-primary text-white' },
  //     ],
  //     processing: false,
  //     projectId: null,
  //     perPage: 10,
  //     totalItems: 0,
  //   }
  // },
  // mounted() {
  //   this.$parent.$on('showProjects', () => {
  //     if (this.$refs['my-modal']) {
  //       this.$refs['my-modal'].show()
  //       this.getProjects()
  //     }
  //   })
  // },
  // methods: {
  //   updateProjectList(newData, updated) {
  //     const newValue = {
  //       name: newData.name,
  //       description: newData.description,
  //       id: newData.uuid,
  //     }
  //     if (updated) {
  //       const allProjects = [...this.rows]
  //       const previous = allProjects.findIndex(arr => arr.id === newData.id)
  //       allProjects[previous] = newValue
  //       this.rows = allProjects
  //       return
  //     }
  //     this.rows = [
  //       ...this.rows, newValue,
  //     ]
  //   },
  //   editProject(value) {
  //     this.$emit('editProject', value)
  //   },
  //   showConfirmDelete(value) {
  //     this.$emit('ConfirmIt', {
  //       id: Number(value.id),
  //       body: 'Are you sure you want to delete project',
  //       title: `Delete ${value.name} ?`,
  //     })
  //   },
  //   handleDelete() {
  //     console.log('todo')
  //   },
  //   showUpdateProjectuser(value) {
  //     this.projectId = value.uuid
  //   },
  //   getProjects(page = 1) {
  //     this.processing = true
  //     useApollo.project.getCompanyProject({
  //       companyId: this.companyId,
  //       first: this.perPage,
  //       page,
  //     }).then(response => {
  //       this.rows = response.data.company?.projects?.data
  //       this.totalItems = response.data.company?.projects?.total
  //     }).catch(error => {
  //       this.showErrorMessage(error)
  //     }).finally(() => { this.processing = false })
  //   },
  // },
}
</script>
