<template>
  <b-modal
    id="invite-user-modal"
    ok-only
    size="lg"
    no-close-on-backdrop
    content-class="shadow"
    :title="title"
    ok-title="Accept"
    @shown="getData"
    @hidden="closeModal"
  >
    <validation-observer
      id="tour-invite-user-form"
      ref="simpleRules"
    >
      <custom-switch
        v-model="isNewUser"
        left-text="Existing User"
        right-text="New User"
        :initial-value="false"
        :show-icons="false"
      />
      <b-form-group label="Email">
        <validation-provider
          #default="{ errors }"
          name="email"
          rules="required|email"
        >
          <v-select
            v-if="!companyInvite && !isNewUser"
            v-model="inviteForm.email"
            :state="errors.length > 0 ? false : null"
            :options="emailOptions"
            :reduce="e => e.value"
            :create-option="email => ({ label: email, value: email })"
            placeholder="Invite user by email"
            taggable
            @search="fetchUsers"
          >
            <template #option="data">
              <span class="font-weight-bold">{{ data.name }}</span><br>
              <span> {{ data.label }}</span>
            </template>
          </v-select>
          <b-form-input
            v-else
            v-model="inviteForm.email"
            placeholder="Invite user by email"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group label="Company">
            <validation-provider
              #default="{ errors }"
              name="Company"
              rules="required"
            >
              <v-select
                v-model="inviteForm.companyId"
                placeholder="Select a Company"
                :options="companies"
                :state="errors.length > 0 ? false : null"
                :reduce="c => c.value"
                :disabled="true"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Role in company">
            <validation-provider
              #default="{ errors }"
              name="Roles"
              rules="required"
            >
              <v-select
                v-model="inviteForm.roleId"
                placeholder="Select a Role"
                :options="roles"
                :state="errors.length > 0 ? false : null"
                :reduce="r => r.value"
                :disabled="emailOptions.some(user => user.value === inviteForm.email)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="!companyInvite">
        <b-col>
          <b-form-group label="Project">
            <validation-provider
              #default="{ errors }"
              name="Project"
              rules="required"
            >
              <v-select
                v-model="inviteForm.projectId"
                placeholder="Select a Project"
                :options="projects"
                :state="errors.length > 0 ? false : null"
                :reduce="p => p.value"
                :disabled="companyInvite ? false : true"
                :clearable="companyInvite ? true : false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            v-if="inviteForm.projectId"
            label="Role in project"
          >
            <validation-provider
              #default="{ errors }"
              name="Roles"
              rules="required"
            >
              <v-select
                v-model="inviteForm.projectRole"
                placeholder="Select a Role"
                :options="projectRoles"
                :state="errors.length > 0 ? false : null"
                :reduce="r => r.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

    </validation-observer>
    <template #modal-footer="{}">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="inviteUser"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span> Send </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BSpinner,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'
import vSelect from 'vue-select'
import CustomSwitch from '@/views/common/components/CustomSwitch.vue'
import { mapGetters } from 'vuex'
import { setComponentReady } from '@/views/tour/tour'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BButton,
    BFormGroup,
    vSelect,
    BFormInput,
    CustomSwitch,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    companyInvite: {
      type: Boolean,
      default: () => false,
    },
    selectedCompany: {
      type: Object,
      default: () => {},
    },
    selectedProject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
      isProcessing: false,
      inviteForm: {
        email: null,
        companyId: this.$store.state.project.selectedCompany,
        projectId: this.$store.state.project.selectedProject,
        roleId: null,
        projectRole: null,
      },
      isNewUser: false,
      roles: [{
        label: 'Select Roles', value: null,
      }],
      projectRoles: [],
      project: {
        name: '',
        uuid: '',
      },
      company: {
        name: '',
        uuid: '',
      },
      emailOptions: [],
    }
  },
  computed: {
    ...mapGetters('project', ['GET_CURRENT_PROJECT', 'GET_CURRENT_COMPANY']),
    title() {
      return 'Invite User'
    },
    companies() {
      return [{
        label: this.company.name,
        value: this.company.uuid,
      }]
    },
    projects() {
      return [{
        label: this.project.name,
        value: this.project.uuid,
      }]
    },
  },
  watch: {
    selectedProject: {
      handler(val) {
        if (val) {
          this.inviteForm.projectId = val.uuid
          this.project.name = val.name
          this.project.uuid = val.uuid
        }
      },
      immediate: true,
    },
    selectedCompany: {
      handler(val) {
        if (val) {
          this.inviteForm.companyId = val.uuid
          this.company.name = val.name
          this.company.uuid = val.uuid
        }
      },
      immediate: true,
    },
    'inviteForm.email': {
      handler(val) {
        if (val) {
          const role = this.emailOptions.find(item => item.label === val)?.role

          if (role) this.inviteForm.roleId = role
          else { this.inviteForm.roleId = 'member' }
        }
      },
    },
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    inviteUser() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.users.inviteUser({
            email: this.inviteForm.email,
            companyUid: this.inviteForm.companyId,
            projectRole: this.companyInvite ? null : {
              projectUid: this.inviteForm.projectId,
              role: this.inviteForm.projectRole,
            },
            role: this.inviteForm.roleId,
          }).then(response => {
            if (response.data.inviteUser.status === 'MUST_VERIFY_EMAIL' || response.data.inviteUser.status === 'USER_INVITED') {
              this.$emit('close')
              this.showSuccessMessage({
                data: {
                  message: response.data.inviteUser.message,
                },
              })
            } else {
              this.showError(response.data.inviteUser.message)
            }
          }).catch(error => {
            this.$bvModal.hide('invite-user-modal')
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
            this.$bvModal.hide('invite-user-modal')
          })
        }
      })
    },
    getRoles() {
      useApollo.company.getAllRoles().then(response => {
        this.projectRoles = response.data?.projectRoles?.data.map(element => ({
          label: element.displayName,
          value: element.name,
        }))
        this.inviteForm.projectRole = this.projectRoles.find(role => role.value === 'member')?.value
        this.roles = response.data.roles.data.map(element => ({
          label: element.displayName,
          value: element.name,
        }))
        this.inviteForm.roleId = this.roles.find(role => role.value === 'member')?.value
      })
    },
    getCompanyRole() {
      useApollo.company.getUserRoles().then(response => {
        this.roles = response.data.roles.data.map(element => ({
          label: element.displayName,
          value: element.name,
        }))
        this.inviteForm.roleId = this.roles.find(role => role.value === 'member')?.value
      })
    },
    fetchUsers(search) {
      if (search.length) {
        useApollo.users.searchUserInCompany({
          keyword: search, companyUid: this.inviteForm.companyId, first: 1, page: 1,
        }).then(response => {
          this.emailOptions = response.data.users.data.map(data => ({
            name: data.text,
            label: data.email,
            value: data.email,
            role: data.companyRoles.data[0].role.name,
          }))
        }).finally(() => { })
      }
    },
    closeModal() {
      this.$emit('close')
      this.project = {}
      this.inviteForm.email = null
      this.inviteForm.roleId = null
      this.inviteForm.projectRole = null
    },
    getData() {
      if (!this.selectedProject) this.project = this.GET_CURRENT_PROJECT
      if (!this.selectedCompany) {
        this.company = this.GET_CURRENT_COMPANY
      }
    },
  },
}
</script>

<style>
#invite-user-modal .custom-radio {
  margin-right: 72px;
}
</style>
