<template>
  <div class="card card-profile">
    <!---->
    <div class="card-body">
      <!----><!---->
      <div class="profile-image-wrapper">
        <div
          class="profile-image"
          :set="random = getRandomColor(value.name )"
        >
          <b-avatar
            button
            size="100"
            :variant="random.color"
            :text="random.character"
            class="align-baseline"
          />
        </div>
      </div>
      <h3>{{ value.name }}</h3>
      <h6
        class="text-muted text-ellipsis"
        :title="value.address"
      >
        {{ value.address }}
      </h6>
      <hr class="mb-2">
      <div class="d-flex justify-content-around align-items-center">
        <div>
          <h6 class="text-muted font-weight-bolder">
            Total Users
          </h6>
          <h3 class="mb-0">
            {{ value.users.total }}
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Teams
          </h6>
          <h3 class="mb-0">
            {{ value.teams.total }}
          </h3>
        </div>
      </div>
      <div class="d-flex pt-2 justify-content-center">
        <!-- <b-button
          variant="outline-success"
          @click="$router.push({
            name: 'companies-projects',
            params: {uid: value.uuid}
          })"
        >
          <feather-icon icon="InfoIcon" /> Projects
        </b-button> -->
        <b-button
          v-if="$store.state.project.companyRole === 'owner'"
          variant="outline-primary"
          class="ml-2"
          @click="$emit('editProject',value)"
        >
          <feather-icon icon="EditIcon" /> Edit
        </b-button>
        <b-button
          v-if="hasInvitePermission"
          v-b-modal.invite-user-modal
          v-b-tooltip="'Invite user to project'"
          variant="outline-success"
          class="ml-2"
          @click="$emit('inviteUser',value)"
        >
          <feather-icon icon="UserPlusIcon" /> Invite
        </b-button>
      </div>
    </div>
    <!-- <project-lists
      :company-id="value.uuid"
      :title="value.name"
    /> -->
    <!----><!---->
  </div>
</template>
<script>
import { BAvatar, BButton } from 'bootstrap-vue'
// import ProjectLists from '@/views/Settings/company/projects/ProjectList.vue'

export default {
  components: {
    BAvatar,
    BButton,
    // ProjectLists,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    hasInvitePermission: {
      type: Boolean,
      default: () => false,
    },
    isAdmin: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>
<style scoped>
.text-ellipsis{
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
